/* eslint-disable import/first */
declare global {
	interface Window {
		__explorer_api_server?: string;
		__insights_api_server?: string;
		__reporting_api_server?: string;
		__insights_map_server?: string;
		__dem_api_server?: string;
	}
}

import { getPathValue } from 'ui-lib/core/utils/json';
import { getStoreState } from 'ui-lib/core/utils/storeRegistry';
import { store } from 'sparky-framework';
import { getAuthState, getState } from '@sparky/framework';
import _ from 'lodash';
import { COMPLETE, INPROGRESS } from 'src/containers/panoramaMigration/constant';
import { getFawkesMainFromSparkyStore } from 'src/appUtils';
import { FAWKES_CONFIG, PANORAMA_CONFIG } from 'src/constants';
//to decouple
export default class StartupManager {
	static getConfigManagedType() {
		const state = getStoreState();
		const type = getPathValue(state, 'main.tenant.type') || StartupManager.getTenantTypeFromSparky() || 'adelphi';
		return type;
	}

	static getMigrationStatus() {
		const state = getStoreState();
		const status = getPathValue(state, 'main.migrationStatus');
		return status;
	}

	static isMigrationStatusFetched() {
		const state = getStoreState();
		const isFetched = getPathValue(state, 'main.migrationStatusFetched') || false;
		return isFetched;
	}

	static isMigrationStateEligibleForFawkesUI() {
		const fawkesMain = getFawkesMainFromSparkyStore() || {};
		const migrationStatus =
			getPathValue(fawkesMain, 'migrationStatus') || getPathValue(store.getState(), 'main.migrationStatus');
		const migrationStatusStatus = _.get(migrationStatus, 'status');
		if (migrationStatus && !_.isEmpty(migrationStatusStatus)) {
			const migrationState = _.get(migrationStatus, 'state', '');
			const prepStepStatus = _.get(migrationStatus, 'status.migration.status', '');
			// const completeStatus = _.get(migrationStatus, 'status.complete-migration.status', '');
			return migrationState === INPROGRESS && prepStepStatus === COMPLETE;
		}
		return false;
	}

	static hideManagementUIBasedOnMigrationState() {
		const fawkesMain = getFawkesMainFromSparkyStore() || {};
		const migrationStatus =
			getPathValue(fawkesMain, 'migrationStatus') || getPathValue(store.getState(), 'main.migrationStatus');
		const migrationState = _.get(migrationStatus, 'state', '');
		return migrationState === 'complete';
	}

	static isMultiTenancy() {
		const state = getStoreState();
		const itemsStr =
			getPathValue(state, 'main.subTenantInfo.data') ||
			getPathValue(store.getState(), 'main.subTenantInfo.data') ||
			'[]';
		const items = typeof itemsStr === 'string' ? JSON.parse(itemsStr) : itemsStr;
		return items.length > 1;
	}

	static isSCMConfigNotEnable = () => {
		const isConfigEnable = _.get(getState(), 'fawkesMain.tenant.scmFlags.is_config_enable', '');
		return isConfigEnable === 'None';
	};

	static isSCMConfigEnable = () => {
		const isConfigEnable = _.get(getState(), 'fawkesMain.tenant.scmFlags.is_config_enable', '');
		return isConfigEnable === 'Enable';
	};

	static isSCMConfigStatusReady = () => {
		const configStatus = _.get(getState(), 'fawkesMain.tenant.scmFlags.config_status', '');
		return configStatus === 'ready';
	};

	static isSCMBase = () => {
		return _.get(getState(), 'fawkesMain.tenant.info.runtime_attributes.is_scm_base', false);
	};

	static isNotSCMBaseOrConfigStatusReady = () => {
		const isSCMBase = _.get(getState?.(), 'fawkesMain.tenant.info.runtime_attributes.is_scm_base', false);
		const isPAE = getAuthState?.()?.instances?.get('prisma_access_edition');
		if (!isSCMBase || isPAE) {
			//we are already checking for managed type in other conditions
			return true;
		}
		const configStatus = _.get(getState?.(), 'fawkesMain.tenant.scmFlags.config_status', '');
		return configStatus === 'ready';
	};

	static getManagedAppName() {
		const type = StartupManager.getConfigManagedType();
		let typeName = 'prisma_access';
		if (type === PANORAMA_CONFIG) {
			typeName = 'prisma_access_panorama';
		}
		return typeName;
	}

	static getTenantTypeFromSparky() {
		if (getAuthState()?.instances?.get('prisma_access_edition_onprem')) {
			return PANORAMA_CONFIG;
		}
		return FAWKES_CONFIG;
	}
}
