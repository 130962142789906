import { _T } from 'src/utils/i18n-utils';

export const RULE_CHECKS = Symbol('ruleChecks');
export const RULE_BASE_CHECKS = Symbol('rulebaseChecks');
export const GROUP_RULE_CHECKS = Symbol('groupRuleChecks');
export const GROUP_FAILED_RULE_CHECKS = Symbol('groupFailedRuleChecks');
export const GROUP_PASSED_RULE_CHECKS = Symbol('groupPassedRuleChecks');
export const BPA_UPDATE_TIME = Symbol('bpaUpdateTime');
export const RULE_FAILED_CHECKS = Symbol('ruleFailedChecks');
export const RULE_BASE_FAILED_CHECKS = Symbol('rulebaseFailedChecks');
export const FAILED_CHECK_RULES = Symbol('failedCheckRules');
export const APP_ID_RULES = Symbol('appIDRules');
export const USER_ID_RULES = Symbol('userIDRules');
export const CONTENT_ID_RULES = Symbol('contentIDRules');
export const RULE_CSC_CHECKS = Symbol('ruleCSCChecks');
export const RULE_BASE_CSC_CHECKS = Symbol('rulebaseCSCChecks');
export const RULE_CSC_FAILED_CHECKS = Symbol('ruleFailedCSCChecks');
export const RULE_BASE_CSC_FAILED_CHECKS = Symbol('rulebaseFailedCSCChecks');
export const ALL_CSC_CHECKS = Symbol('allCSCChecks');
export const PROFILE_CHECKS = Symbol('profileChecks');
export const FAILED_PROFILE_CHECKS = Symbol('failedProfileChecks');
export const FAILED_PROFILE = Symbol('failedProfile');
export const PROFILE_NOT_IN_USE = Symbol('profileNotInUsed');
export const FAILED_PROFILE_CSC_CHECKS = Symbol('failedProfileCSCChecks');
export const ALL_PROFILE_BPA = Symbol('allProfileBpa');
export const GLOBAL_CHECK = Symbol('globalCheck');
export const RULE = Symbol('rule');
export const RULE_BASE = Symbol('ruleBase');

export const BPA_TYPE_POLICY = 'policy';
export const BPA_TYPE_PROFILE = 'profile';
export const DEFAULT_MAX_ENTRY_COUNT = 5;
export const contentIDs = [
	{
		name: 'antiSpyware',
		text: _T('Anti-Spyware'),
		value: ['anti_spyware_profile_enabled'],
	},
	{
		name: 'dnsSecurity',
		text: _T('DNS Security'),
		value: ['dns_sinkhole_enabled'],
	},
	{
		name: 'fileBlocking',
		text: _T('File Blocking'),
		value: ['file_blocking_profile_enabled'],
	},
	{
		name: 'urlAccess',
		text: _T('URL Access'),
		value: ['url_filtering_profile_enabled'],
	},
	{
		name: 'vulnerability',
		text: _T('Vuln. Protection'),
		value: ['vulnerability_protection_profile_enabled'],
	},
	{
		name: 'wildFire',
		text: _T('WildFire & AV'),
		value: [
			'wildfire_analysis_profile_enabled',
			'antivirus_wildfire_analysis_profile_enabled',
			'antivirus_profile_enabled',
		],
	},
];

export const STRATA_CLOUD_MANAGER = 'Strata Cloud Manager';
export const SASE_PRISMA_ACCESS_BRAND = 'Prisma Access';
export const PRISMA_SASE_BRAND = 'Prisma SASE';
export const PRISMA_SDWAN_BRAND = 'Prisma SD-WAN';
export const CISCO_CATALYST_SDWAN_BRAND = 'Cisco Catalyst SD-WAN';
export const MU_RN_ADEM_ADDON_LICENSE = 'MURNDEMPAE';
export const MU_ADEM_ADDON_LICENSE = 'MUDEMPAE';
export const RN_ADEM_ADDON_LICENSE = 'RNDEMPAE';
export const EVAL_MU_RN_ADEM_ADDON_LICENSE = 'EVALMURNDEMPAE';
export const EVAL_MU_ADEM_ADDON_LICENSE = 'EVALMUDEMPAE';
export const EVAL_RN_ADEM_ADDON_LICENSE = 'EVALRNDEMPAE';
export const SE_MU_RN_ADEM_ADDON_LICENSE = 'SEMURNDEMPAE';
export const SE_MU_ADEM_ADDON_LICENSE = 'SEMUDEMPAE';
export const SE_RN_ADEM_ADDON_LICENSE = 'SERNDEMPAE';
export const LAB_MU_RN_ADEM_ADDON_LICENSE = 'LAB-MU-RN-DEMPAE';
export const LAB_MU_ADEM_ADDON_LICENSE = 'LAB-MU-DEMPAE';
export const LAB_RN_ADEM_ADDON_LICENSE = 'LAB-RN-DEMPAE';
export const NFR_MU_RN_ADEM_ADDON_LICENSE = 'NFR-MU-RN-DEMPAE';
export const NFR_MU_ADEM_ADDON_LICENSE = 'NFR-MU-DEMPAE';
export const NFR_RN_ADEM_ADDON_LICENSE = 'NFR-RN-DEMPAE';

export const PAN_PRISMA_ACCESS_MU_ADEM_AIOPS = 'MUADEMAIOPSPAE';
export const PAN_PRISMA_ACCESS_NET_ADEM_AIOPS = 'RNADEMAIOPSPAE';
export const PAN_PRISMA_ACCESS_ADEM_AIOPS = 'MURNADEMAIOPSPAE';
export const PAN_PRISMA_ACCESS_NFR_ADEM_AIOPS = 'NFRMURNADEMAIOPSPAE';
export const PAN_PRISMA_ACCESS_LAB_ADEM_AIOPS = 'LABMURNADEMAIOPSPAE';
export const PAN_PRISMA_ACCESS_ADEM_AIOPS_SE = 'SEMURNADEMAIOPSPAE';
export const PAN_PRISMA_ACCESS_ADEM_AIOPS_E30 = 'EVALMURNADEMAIOPSPAE';

// ADEM-X License (ADI-23559)
// AIOPS
export const PAN_X_MU_ADEM_AI = 'MUADEMXAIPAE';
export const PAN_X_NET_ADEM_AI = 'RNADEMXAIPAE';
export const PAN_X_ADEM_AI = 'MURNADEMXAIPAE';
export const PAN_X_ADEM_AI_NFR = 'NFRMURNADEMXAIPAE';
export const PAN_X_ADEM_AI_LAB = 'LABMURNADEMXAIPAE';
export const PAN_X_ADEM_AI_SE = 'SEMURNADEMXAIPAE';
export const PAN_X_ADEM_AI_EVAL = 'EVALMURNADEMXAIPAE';

// Observability
export const PAN_X_MU_ADEM_OB_ONLY = 'MUADEMXOBPAE';
export const PAN_X_NET_ADEM_OB_ONLY = 'RNADEMXOBPAE';
export const PAN_X_ADEM_OB_ONLY = 'MURNADEMXOBPAE';
export const PAN_X_ADEM_OB_ONLY_NFR = 'NFRMURNADEMXOBPAE';
export const PAN_X_ADEM_OB_ONLY_LAB = 'LABMURNADEMXOBPAE';
export const PAN_X_ADEM_OB_ONLY_SE = 'SEMURNADEMXOBPAE';
export const PAN_X_ADEM_OB_ONLY_EVAL = 'EVALMURNADEMXOBPAE';

// Base
export const PAN_X_ADEM_BASE = 'BASEADEMXPAE';

// EnpointDLP
export const ENPOINT_DLP_ADDON_LICENSE = 'EPDLP';
export const ENPOINT_DLP_ADDON_LICENSE_EVAL = 'EPDLP-EVAL';

export const ADEM_AIOPS_LICENSE_TYPES = [
	PAN_PRISMA_ACCESS_MU_ADEM_AIOPS,
	PAN_PRISMA_ACCESS_NET_ADEM_AIOPS,
	PAN_PRISMA_ACCESS_ADEM_AIOPS,
	PAN_PRISMA_ACCESS_NFR_ADEM_AIOPS,
	PAN_PRISMA_ACCESS_LAB_ADEM_AIOPS,
	PAN_PRISMA_ACCESS_ADEM_AIOPS_SE,
	PAN_PRISMA_ACCESS_ADEM_AIOPS_E30,
	PAN_X_MU_ADEM_AI,
	PAN_X_NET_ADEM_AI,
	PAN_X_ADEM_AI,
	PAN_X_ADEM_AI_NFR,
	PAN_X_ADEM_AI_LAB,
	PAN_X_ADEM_AI_SE,
	PAN_X_ADEM_AI_EVAL,
	PAN_X_MU_ADEM_OB_ONLY,
	PAN_X_NET_ADEM_OB_ONLY,
	PAN_X_ADEM_OB_ONLY,
	PAN_X_ADEM_OB_ONLY_NFR,
	PAN_X_ADEM_OB_ONLY_LAB,
	PAN_X_ADEM_OB_ONLY_SE,
	PAN_X_ADEM_OB_ONLY_EVAL,
	PAN_X_ADEM_BASE,
];
export const SHARED_ADEM_ADDON_LICENSE_TYPES = [
	MU_RN_ADEM_ADDON_LICENSE,
	EVAL_MU_RN_ADEM_ADDON_LICENSE,
	SE_MU_RN_ADEM_ADDON_LICENSE,
	LAB_MU_RN_ADEM_ADDON_LICENSE,
	NFR_MU_RN_ADEM_ADDON_LICENSE,
];
export const MU_ADEM_ADDON_LICENSE_TYPES = [
	MU_ADEM_ADDON_LICENSE,
	EVAL_MU_ADEM_ADDON_LICENSE,
	SE_MU_ADEM_ADDON_LICENSE,
	LAB_MU_ADEM_ADDON_LICENSE,
	NFR_MU_ADEM_ADDON_LICENSE,
];
export const RN_ADEM_ADDON_LICENSE_TYPES = [
	RN_ADEM_ADDON_LICENSE,
	EVAL_RN_ADEM_ADDON_LICENSE,
	SE_RN_ADEM_ADDON_LICENSE,
	LAB_RN_ADEM_ADDON_LICENSE,
	NFR_RN_ADEM_ADDON_LICENSE,
];
export const ADEM_ADDON_LICENSE_TYPES = [
	...SHARED_ADEM_ADDON_LICENSE_TYPES,
	...MU_ADEM_ADDON_LICENSE_TYPES,
	...RN_ADEM_ADDON_LICENSE_TYPES,
	...ADEM_AIOPS_LICENSE_TYPES,
];

// TODO: Need translation? Check with Srikant/Madhavi
export const SHARED_ADEM_ADDON_LICENSE_DISPLAY = {
	size: '{parsedSize} Mobile Users and {parsedSize} Net (Mbps)',
};
export const MU_ADEM_ADDON_LICENSE_DISPLAY = {
	size: '{parsedSize} Mobile Users ',
};
export const RN_ADEM_ADDON_LICENSE_DISPLAY = {
	size: '{parsedSize} Net (Mbps)',
};

export const EXCESS_DATA_TRANSFER_ADDON_LICENSE = 'X-D-TRNSFR';

//Location Add on License
export const PA_ADD_LOC = 'PA-ADD-LOC';

export const PRISMA_ACCESS_BROSWER_LICENSE_APP_ID = 'seb';
export const DLP_ADDON_LICENSE_APP_ID = 'dlp';
export const PA_DLP_ADDON_LICENSE = 'PA-DLP';
export const EVAL_PA_DLP_ADDON_LICENSE = 'EVAL-PA-DLP';
export const SE_PA_DLP_ADDON_LICENSE = 'SE-PA-DLP';
export const LAB_PA_DLP_ADDON_LICENSE = 'LAB-PA-DLP';
export const DLP_ADDON_LICENSE_TYPES = [
	PA_DLP_ADDON_LICENSE,
	EVAL_PA_DLP_ADDON_LICENSE,
	SE_PA_DLP_ADDON_LICENSE,
	LAB_PA_DLP_ADDON_LICENSE,
];
export const DLP_ADDON_LICENSE_DISPLAY = {
	name: 'Data Loss Prevention',
};

export const CASB_LICENSE_BUNDLE_APP_ID = 'casb';
export const NG_CASB_X_LICENSE_BUNDLE_APP_ID = 'ng_casb';
export const LOCATION_ADDON_APP_ID = 'add_loc';
export const ZTNA_ADDON_APP_ID = 'add_ztna';
export const RBI_ADDON_APP_ID = 'rbi';
export const IOT_LICENSE_APP_ID = 'zingbox';

export const CASB_LICENSE_BUNDLE_DISPLAY = {
	name: 'CASB',
	bundle: 'SaaS Inline, DLP, SaaS API, DLP API and SSPM',
};
export const NG_CASB_X_LICENSE_BUNDLE_DISPLAY = {
	name: 'CASB',
	bundle: 'SaaS Inline, DLP, SaaS API, DLP API and SSPM',
};

export const LOCATION_ADDON_DISPLAY = {
	name: `${SASE_PRISMA_ACCESS_BRAND} Locations (Addon)`,
};

export const RBI_ADDON_DISPLAY = {
	name: 'Remote Browser Isolation',
};

export const SD_WAN_LICENSE_BUNDLE_APP_ID = 'cgx';
export const SD_WAN_LICENSE_BUNDLE_DISPLAY = {
	name: 'SD-WAN',
};

export const SAAS_INLINE_ADDON_LICENSE_APP_ID = 'aperture';
export const TRAFFIC_REPLICATION_ADDON_LICENSE_APP_ID = 'add_traffic_mirroring';
export const MU_RN_SAAS_INLINE_ADDON_LICENSE = 'SAASINLINEPAE';
export const MU_SAAS_INLINE_ADDON_LICENSE = 'MUSAASINLINEPAE';
export const RN_SAAS_INLINE_ADDON_LICENSE = 'RNSAASINLINEPAE';
export const EVAL_MU_RN_SAAS_INLINE_ADDON_LICENSE = 'EVALSAASINLINEPAE';
export const EVAL_MU_SAAS_INLINE_ADDON_LICENSE = 'EVALMUSAASINLINEPAE';
export const EVAL_RN_SAAS_INLINE_ADDON_LICENSE = 'EVALRNSAASINLINEPAE';
export const SE_MU_RN_SAAS_INLINE_ADDON_LICENSE = 'SESAASINLINEPAE';
export const SE_MU_SAAS_INLINE_ADDON_LICENSE = 'SEMUSAASINLINEPAE';
export const SE_RN_SAAS_INLINE_ADDON_LICENSE = 'SERNSAASINLINEPAE';
export const LAB_MU_RN_SAAS_INLINE_ADDON_LICENSE = 'LAB-MU-RN-SAASINLPAE';
export const LAB_MU_SAAS_INLINE_ADDON_LICENSE = 'LAB-MU-SAASINLPAE';
export const LAB_RN_SAAS_INLINE_ADDON_LICENSE = 'LAB-RN-SAASINLPAE';
export const NFR_MU_RN_SAAS_INLINE_ADDON_LICENSE = 'NFR-MU-RN-SAASINLPAE';
export const NFR_MU_SAAS_INLINE_ADDON_LICENSE = 'NFR-MU-SAASINLPAE';
export const NFR_RN_SAAS_INLINE_ADDON_LICENSE = 'NFR-RN-SAASINLPAE';
export const SHARED_SAAS_INLINE_ADDON_LICENSE_TYPES = [
	MU_RN_SAAS_INLINE_ADDON_LICENSE,
	EVAL_MU_RN_SAAS_INLINE_ADDON_LICENSE,
	SE_MU_RN_SAAS_INLINE_ADDON_LICENSE,
	LAB_MU_RN_SAAS_INLINE_ADDON_LICENSE,
	NFR_MU_RN_SAAS_INLINE_ADDON_LICENSE,
];
export const MU_SAAS_INLINE_ADDON_LICENSE_TYPES = [
	MU_SAAS_INLINE_ADDON_LICENSE,
	EVAL_MU_SAAS_INLINE_ADDON_LICENSE,
	SE_MU_SAAS_INLINE_ADDON_LICENSE,
	LAB_MU_SAAS_INLINE_ADDON_LICENSE,
	NFR_MU_SAAS_INLINE_ADDON_LICENSE,
];
export const RN_SAAS_INLINE_ADDON_LICENSE_TYPES = [
	RN_SAAS_INLINE_ADDON_LICENSE,
	EVAL_RN_SAAS_INLINE_ADDON_LICENSE,
	SE_RN_SAAS_INLINE_ADDON_LICENSE,
	LAB_RN_SAAS_INLINE_ADDON_LICENSE,
	NFR_RN_SAAS_INLINE_ADDON_LICENSE,
];
export const SAAS_INLINE_ADDON_LICENSE_TYPES = [
	...SHARED_SAAS_INLINE_ADDON_LICENSE_TYPES,
	...MU_SAAS_INLINE_ADDON_LICENSE_TYPES,
	...RN_SAAS_INLINE_ADDON_LICENSE_TYPES,
];
// TODO: Need translation? Check with Srikant/Madhavi
export const SHARED_SAAS_INLINE_ADDON_LICENSE_DISPLAY = {
	size: '{parsedSize} Mobile Users and {parsedSize} Net (Mbps)',
};
export const MU_SAAS_INLINE_ADDON_LICENSE_DISPLAY = {
	size: '{parsedSize} Mobile Users',
};
export const RN_SAAS_INLINE_ADDON_LICENSE_DISPLAY = {
	size: '{parsedSize} Net (Mbps)',
};

export const CUSTOMIZED_ADDON_LICENSE_DISPLAY_MAP = {
	[PA_DLP_ADDON_LICENSE]: DLP_ADDON_LICENSE_DISPLAY,
	[EVAL_PA_DLP_ADDON_LICENSE]: DLP_ADDON_LICENSE_DISPLAY,
	[SE_PA_DLP_ADDON_LICENSE]: DLP_ADDON_LICENSE_DISPLAY,
	[LAB_PA_DLP_ADDON_LICENSE]: DLP_ADDON_LICENSE_DISPLAY,
	[MU_RN_ADEM_ADDON_LICENSE]: SHARED_ADEM_ADDON_LICENSE_DISPLAY,
	[MU_ADEM_ADDON_LICENSE]: MU_ADEM_ADDON_LICENSE_DISPLAY,
	[RN_ADEM_ADDON_LICENSE]: RN_ADEM_ADDON_LICENSE_DISPLAY,
	[EVAL_MU_RN_ADEM_ADDON_LICENSE]: SHARED_ADEM_ADDON_LICENSE_DISPLAY,
	[EVAL_MU_ADEM_ADDON_LICENSE]: MU_ADEM_ADDON_LICENSE_DISPLAY,
	[EVAL_RN_ADEM_ADDON_LICENSE]: RN_ADEM_ADDON_LICENSE_DISPLAY,
	[SE_MU_RN_ADEM_ADDON_LICENSE]: SHARED_ADEM_ADDON_LICENSE_DISPLAY,
	[SE_MU_ADEM_ADDON_LICENSE]: MU_ADEM_ADDON_LICENSE_DISPLAY,
	[SE_RN_ADEM_ADDON_LICENSE]: RN_ADEM_ADDON_LICENSE_DISPLAY,
	[LAB_MU_RN_ADEM_ADDON_LICENSE]: SHARED_ADEM_ADDON_LICENSE_DISPLAY,
	[LAB_MU_ADEM_ADDON_LICENSE]: MU_ADEM_ADDON_LICENSE_DISPLAY,
	[LAB_RN_ADEM_ADDON_LICENSE]: RN_ADEM_ADDON_LICENSE_DISPLAY,
	[NFR_MU_RN_ADEM_ADDON_LICENSE]: SHARED_ADEM_ADDON_LICENSE_DISPLAY,
	[NFR_MU_ADEM_ADDON_LICENSE]: MU_ADEM_ADDON_LICENSE_DISPLAY,
	[NFR_RN_ADEM_ADDON_LICENSE]: RN_ADEM_ADDON_LICENSE_DISPLAY,
	[MU_RN_SAAS_INLINE_ADDON_LICENSE]: SHARED_SAAS_INLINE_ADDON_LICENSE_DISPLAY,
	[MU_SAAS_INLINE_ADDON_LICENSE]: MU_SAAS_INLINE_ADDON_LICENSE_DISPLAY,
	[RN_SAAS_INLINE_ADDON_LICENSE]: RN_SAAS_INLINE_ADDON_LICENSE_DISPLAY,
	[EVAL_MU_RN_SAAS_INLINE_ADDON_LICENSE]: SHARED_SAAS_INLINE_ADDON_LICENSE_DISPLAY,
	[EVAL_MU_SAAS_INLINE_ADDON_LICENSE]: MU_SAAS_INLINE_ADDON_LICENSE_DISPLAY,
	[EVAL_RN_SAAS_INLINE_ADDON_LICENSE]: RN_SAAS_INLINE_ADDON_LICENSE_DISPLAY,
	[SE_MU_RN_SAAS_INLINE_ADDON_LICENSE]: SHARED_SAAS_INLINE_ADDON_LICENSE_DISPLAY,
	[SE_MU_SAAS_INLINE_ADDON_LICENSE]: MU_SAAS_INLINE_ADDON_LICENSE_DISPLAY,
	[SE_RN_SAAS_INLINE_ADDON_LICENSE]: RN_SAAS_INLINE_ADDON_LICENSE_DISPLAY,
	[LAB_MU_RN_SAAS_INLINE_ADDON_LICENSE]: SHARED_SAAS_INLINE_ADDON_LICENSE_DISPLAY,
	[LAB_MU_SAAS_INLINE_ADDON_LICENSE]: MU_SAAS_INLINE_ADDON_LICENSE_DISPLAY,
	[LAB_RN_SAAS_INLINE_ADDON_LICENSE]: RN_SAAS_INLINE_ADDON_LICENSE_DISPLAY,
	[NFR_MU_RN_SAAS_INLINE_ADDON_LICENSE]: SHARED_SAAS_INLINE_ADDON_LICENSE_DISPLAY,
	[NFR_MU_SAAS_INLINE_ADDON_LICENSE]: MU_SAAS_INLINE_ADDON_LICENSE_DISPLAY,
	[NFR_RN_SAAS_INLINE_ADDON_LICENSE]: RN_SAAS_INLINE_ADDON_LICENSE_DISPLAY,
	[CASB_LICENSE_BUNDLE_APP_ID]: CASB_LICENSE_BUNDLE_DISPLAY,
	[SD_WAN_LICENSE_BUNDLE_APP_ID]: SD_WAN_LICENSE_BUNDLE_DISPLAY,
	[NG_CASB_X_LICENSE_BUNDLE_APP_ID]: NG_CASB_X_LICENSE_BUNDLE_DISPLAY,
	[LOCATION_ADDON_APP_ID]: LOCATION_ADDON_DISPLAY,
	[RBI_ADDON_APP_ID]: RBI_ADDON_DISPLAY,
};

// TODO: Revisit how this is done so we can do translation
export const PRISMA_ACCESS_CONTAINER = 'Prisma Access';
export const WEB_SECURITY_CONTAINER = 'swg-container';
export const MU_CONTAINER = 'Mobile Users Container';
export const RN_CONTAINER = 'Remote Networks';
export const SC_CONTAINER = 'Service Connections';
export const MU_GP_CONTAINER = 'Mobile Users';
export const MU_JP_CONTAINER = 'Mobile Users'; //todo jupiter
export const JUPITER = 'Prisma Access Agent';
export const ACCESS_AGENT = 'Access Agent';
export const GLOBALPROTECT = 'GlobalProtect';
export const EXPLICIT_PROXY = 'Explicit Proxy';
export const AGENTLESS_ACCESS = 'Agentless Access';
export const MU_EP_CONTAINER = 'Mobile Users Explicit Proxy';
export const NGFW_SHARED = 'ngfw-shared';
export const PRISMA_ACCESS_KEY = 'prisma-access';
export const ALL = 'All';
export const ROOT_KEY = 'root';
export const MU_KEY = 'mobile-users';
export const RN_KEY = 'remote-networks';
export const SC_KEY = 'service-connections';
export const MU_GP_KEY = 'mobile-users-gp';
export const MU_JP_KEY = 'mobile-users-jp';
export const MU_GP_KEY_2 = 'mu-device';
export const MU_EP_KEY = 'mobile-users-ep';
export const CONTAINER = 'container';
export const CONTAINER_CLOUD = 'cloud';
export const CONTAINER_ONPREM = 'on-prem';
export const SWG_CONTAINER = 'web-security-config';
export const SWG_CONTAINER_TYPE = 'swg-container';
export const SNIPPET = 'snippet';
export const PUBLISHED_SNIPPET_SCOPE = 'published';
export const SUBSCRIBED_SNIPPET_SCOPE = 'subscribed';
export const LOCAL_SNIPPET_SCOPE = 'local';
export const DEVICE = 'device';
export const FOLDER = 'folder';
export const DEFAULT_SNIPPET = 'predefined-snippet';
export const COLO_CONNECT = 'colo-connect';
export const COLO_CONNECT_TYPE = 'Colo Connect';
export const WEB_SECURITY_DEFAULT_SNIPPET = 'Web-Security-Default';
export const IOT_TAG_NAME = 'IoT Recommendation';
export const IOT_DEVICE_PROFILE_DESCRIPTION = 'IoT policy recommendation profile';
export const AGENTLESS_ACCESS_LOC_KEY = 'agentless-access';
export const SAAS_INLINE_POL_RECOMMENDATION_SNIPPET = 'SAAS-Inline-Pol-Recommendation';
export const POLICY_OPTIMIZER_ANNOUNCEMENT_KEY = 'po-announcement';
export const UNLIMITED_PRIVATE_APP_ACCESS_ADD_ON = 'unlimited private_app_access';

export const GPCS_CONTAINERS: any = {
	[PRISMA_ACCESS_KEY]: { name: PRISMA_ACCESS_CONTAINER, type: CONTAINER, locationPath: `${PRISMA_ACCESS_CONTAINER}` },
	[MU_KEY]: { name: MU_CONTAINER, type: CONTAINER, locationPath: `${MU_CONTAINER}` },
	[RN_KEY]: { name: RN_CONTAINER, type: CONTAINER_CLOUD, locationPath: `${RN_CONTAINER}` },
	[SC_KEY]: { name: SC_CONTAINER, type: CONTAINER_CLOUD, locationPath: `${SC_CONTAINER}` },
	[MU_GP_KEY]: { name: MU_GP_CONTAINER, type: CONTAINER_CLOUD, locationPath: `${MU_GP_CONTAINER}` },
	[MU_GP_KEY_2]: { name: MU_GP_CONTAINER, type: CONTAINER_CLOUD, locationPath: `${MU_GP_CONTAINER}` },
	[MU_JP_KEY]: { name: MU_JP_CONTAINER, type: CONTAINER_CLOUD, locationPath: `${MU_JP_CONTAINER}` },
	[MU_EP_KEY]: { name: MU_EP_CONTAINER, type: CONTAINER_CLOUD, locationPath: `${MU_EP_CONTAINER}` },
	[SWG_CONTAINER]: { name: SWG_CONTAINER_TYPE, type: SWG_CONTAINER_TYPE, locationPath: `${SWG_CONTAINER_TYPE}` },
	[AGENTLESS_ACCESS_LOC_KEY]: { name: MU_GP_CONTAINER, type: CONTAINER_CLOUD, locationPath: `${MU_GP_CONTAINER}` },
	[COLO_CONNECT]: { name: COLO_CONNECT_TYPE, type: CONTAINER_CLOUD, locationPath: `${COLO_CONNECT_TYPE}` },
};

export const LOCATION_KEY_MAP: any = {
	[ROOT_KEY]: ALL,
	[PRISMA_ACCESS_KEY]: PRISMA_ACCESS_CONTAINER,
	[MU_KEY]: MU_CONTAINER,
	[RN_KEY]: RN_CONTAINER,
	[SC_KEY]: SC_CONTAINER,
	[MU_GP_KEY]: MU_GP_CONTAINER,
	[MU_JP_KEY]: MU_JP_CONTAINER,
	[MU_GP_KEY_2]: MU_GP_CONTAINER,
	[MU_EP_KEY]: MU_EP_CONTAINER,
	[SWG_CONTAINER]: SWG_CONTAINER_TYPE,
	[COLO_CONNECT]: COLO_CONNECT_TYPE,
};

export const LOCATION_REVERSE_KEY_MAP: any = {
	[PRISMA_ACCESS_CONTAINER]: PRISMA_ACCESS_KEY,
	[MU_CONTAINER]: MU_KEY,
	[RN_CONTAINER]: RN_KEY,
	[SC_CONTAINER]: SC_KEY,
	[MU_GP_CONTAINER]: MU_GP_KEY,
	[MU_JP_CONTAINER]: MU_JP_KEY,
	[MU_EP_CONTAINER]: MU_EP_KEY,
	[SWG_CONTAINER_TYPE]: SWG_CONTAINER,
	[COLO_CONNECT_TYPE]: COLO_CONNECT,
};
export const LOCATION_REVERSE_KEY_MAP_SWG_DISABLED = {
	[PRISMA_ACCESS_CONTAINER]: PRISMA_ACCESS_KEY,
	[MU_CONTAINER]: MU_KEY,
	[RN_CONTAINER]: RN_KEY,
	[SC_CONTAINER]: SC_KEY,
	[MU_GP_CONTAINER]: MU_GP_KEY_2,
	[MU_JP_CONTAINER]: MU_JP_KEY,
	[MU_EP_CONTAINER]: MU_EP_KEY,
	[SWG_CONTAINER_TYPE]: SWG_CONTAINER,
};

export const EPDLP_ADDON_LICENSE_TYPES = [ENPOINT_DLP_ADDON_LICENSE, ENPOINT_DLP_ADDON_LICENSE_EVAL];

export const NGFW_SHARED_CONTAINER = {
	container: {
		name: NGFW_SHARED,
		type: CONTAINER,
		locationPath: NGFW_SHARED,
	},
};

export const PRISMA_ACCESS_RBA = {
	coloConnect: 'colo_connect',
	globalProtect_BB: 'globalprotect',
	proxy_chaining: '',
};

export const XPATH_MAP = {
	globalProtectGateway: 'global-protect/global-protect-gateway',
	networkGlobalProtectGateway: `devices/entry[@name='localhost.localdomain']/network/tunnel/global-protect-gateway`,
};

export const XPATH_MAP_SNMPSETTING = {
	views: "devices/entry[@name='localhost.localdomain']/deviceconfig/system/snmp-setting/access-setting/version/v3/views",
	users: "devices/entry[@name='localhost.localdomain']/deviceconfig/system/snmp-setting/access-setting/version/v3/users",
	communityString:
		"devices/entry[@name='localhost.localdomain']/deviceconfig/system/snmp-setting/access-setting/version/v2c/snmp-community-string",
	version_v2c:
		"devices/entry[@name='localhost.localdomain']/deviceconfig/system/snmp-setting/access-setting/version/v2c",
	version_v3:
		"devices/entry[@name='localhost.localdomain']/deviceconfig/system/snmp-setting/access-setting/version/v3",
	snmpSystem: "devices/entry[@name='localhost.localdomain']/deviceconfig/system/snmp-setting/snmp-system",
};

export const SNIPPET_SHARING_RBA = {
	trustEstablish: 'trust_establish',
	snippetPublish: 'snippet_publish',
};

export const TRUSTED_TENANTS_TYPES = {
	publisher: 'publisher' as const,
	subscriber: 'subscriber' as const,
};

export const CONTAINER_TYPE_MAP = {
	[CONTAINER_ONPREM]: DEVICE,
	[CONTAINER]: FOLDER,
	[CONTAINER_CLOUD]: FOLDER,
	[SNIPPET]: SNIPPET,
};

export const PROJECT_ADMIN = 'project_admin';
export const PROJECT_ADMIN_PUSH = 'project_admin_push';
export const SWG_RULE = 'swg';
export const INTERNET_ACCESS_POLICY = 'Internet Access Policy';
export const SECURITY_POLICY = 'Security Policy';
export const AGG_BANDWIDTH = 'agg-bandwidth';
export const SITE = 'site';
export const SECURITY_POSTURE_RBA_PATH = 'security_posture';
